import { FiMail, FiPhone, FiFacebook } from "react-icons/fi";
import { Grid, Box, Field, Textarea, Text, Button, Heading } from "theme-ui";
import * as React from 'react';
export default {
  FiMail,
  FiPhone,
  FiFacebook,
  Grid,
  Box,
  Field,
  Textarea,
  Text,
  Button,
  Heading,
  React
};